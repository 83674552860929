import {
  Component,
  For,
  createSignal,
  onMount,
  createEffect,
  Show,
} from "solid-js";

import PressetexteRow from "./pressetext-row/pressetexte-row";

import "./pressetexte.scss";

import {
  deletePressetext,
  updatePressetext,
} from "../../services/api/search-api-handler";
import { token } from "../../utils/auth-token";
import { pressetextStore } from "../../store";
import { showToggledContent } from "../shared/navigation/utils/navigation.utils";
import { COLLAPSIBLE_CONTENT } from "../../constants";
import { usePressetexte } from "../../store/pressetexte-store";
const { setPressetextState } = pressetextStore;

const Pressetexte: Component = () => {
  const [pressetexte, { refetch }] = usePressetexte();
  const [deletePressetextId, setDeletePressetextId] = createSignal("");

  createEffect(() => {
    if (token() !== null) {
      refetch();
    }
  });

  let deleteDialog;
  onMount(() => {
    deleteDialog = document.getElementById("delete-pressetext-dialog");
  });
  const closeDeleteDialog = () => {
    deleteDialog.dispatchEvent(new Event("delete-pressetext:dialog:close"));
  };

  const handleDeleteDialog = async (event, id) => {
    setDeletePressetextId(id);
    const thema = pressetexte().filter(
      (item) => item.id === deletePressetextId(),
    )?.[0]?.thema;
    deleteDialog.querySelector("p").innerText =
      `Wollen Sie den Pressetext für das Thema "${thema}" unwiderruflich löschen?`;
    deleteDialog.dispatchEvent(new Event("delete-pressetext:dialog:open"));
  };

  const handleDelete = async () => {
    const response = await deletePressetext(deletePressetextId());
    if (response.ok) {
      refetch();
      setDeletePressetextId("");
      closeDeleteDialog();
    }
  };

  const handleUpdate = async (pressetext) => {
    const response = await updatePressetext(pressetext);
    if (response.ok) {
      refetch();
    }
  };

  const addNewRow = () => {
    setPressetextState("selectedTopic", undefined);
    showToggledContent(COLLAPSIBLE_CONTENT.NEW_PRESSETEXT);
  };

  return (
    <>
      <section class="section-append" />
      <section class="content">
        <tp-button
          appearance="secondary"
          onClick={addNewRow}
          style={{ "margin-left": "12px" }}
        >
          NEUEN PRESSETEXT ANLEGEN
        </tp-button>

        {pressetexte.loading ? (
          <tp-circular-progress />
        ) : (
          <Show
            when={
              pressetexte() &&
              pressetexte().length > 0 &&
              // TODO we're filtering here and at other places, too. Make it DRY.
              pressetexte().filter((p) => {
                return !p.draft;
              }).length > 0
            }
          >
            <For
              // TODO we're filtering here and at other places, too. Make it DRY.
              each={pressetexte().filter((p) => {
                return !p.draft;
              })}
            >
              {(pressetext) => {
                return (
                  <PressetexteRow
                    pressetext={pressetext}
                    handleDelete={handleDeleteDialog}
                    handleSave={handleUpdate}
                    isDraft={false}
                  />
                );
              }}
            </For>
          </Show>
        )}

        <tp-dialog dialog-id="delete-pressetext" id="delete-pressetext-dialog">
          <div slot="title">Pressetext löschen</div>

          <p />

          <div slot="action_buttons">
            <tp-button appearance="text-dark" onClick={closeDeleteDialog}>
              ZURÜCK
            </tp-button>
            <tp-button id="deleteButton" onClick={handleDelete}>
              LÖSCHEN
            </tp-button>
          </div>
        </tp-dialog>
      </section>
      <br />
    </>
  );
};

export default Pressetexte;
