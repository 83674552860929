import { Component, createSignal, onMount } from "solid-js";

import PressetexteRow from "./pressetext-row/pressetexte-row";
import "./new-pressetext.scss";
import {
  savePressetext,
  updatePressetext,
} from "../../services/api/search-api-handler";
import { createStore } from "solid-js/store";
import { PressetextType } from "../../store";
import { usePressetexte } from "../../store/pressetexte-store";

const NewPressetext: Component = () => {
  const [, { refetch }] = usePressetexte();
  const [savePressetextData, setSavePressetextData] =
    createSignal<PressetextType>();
  const [metaDataStore, setMetaDataStore] = createStore({
    title: "",
    urheber: "",
  });

  let saveDialog;
  onMount(() => {
    saveDialog = document.getElementById("save-pressetext-dialog");
  });
  const closeSaveDialog = () => {
    saveDialog.dispatchEvent(new Event("save-pressetext:dialog:close"));
  };

  const handleSaveDialog = async (pressetext) => {
    setSavePressetextData(pressetext);
    saveDialog.querySelector("p").innerText =
      `Bitte geben Sie einen Dateinamen und Urheber des Pressetextes ein:`;
    saveDialog.dispatchEvent(new Event("save-pressetext:dialog:open"));
  };

  const handleSave = async () => {
    let pressetext = savePressetextData();
    pressetext = JSON.parse(JSON.stringify(pressetext));
    pressetext.draft = false;
    pressetext.title = metaDataStore.title;
    pressetext.urheber = metaDataStore.urheber;

    let updateOrSave: (pressetext: PressetextType) => Promise<Response>;
    if (pressetext.pressetextId) {
      updateOrSave = updatePressetext;
    } else {
      updateOrSave = savePressetext;
    }
    const response = await updateOrSave(pressetext);
    if (response.ok) {
      refetch();
      setSavePressetextData(undefined);
      setMetaDataStore(undefined);
      closeSaveDialog();
    }
  };

  return (
    <>
      <section class="section-append">
        <div class="content">
          <p>
            Bitte füllen Sie die folgenden Felder möglichst vollständig aus.
            Beachten Sie, dass der Text mithilfe Künstlicher Intelligenz
            erstellt wird und somit nur eine Grundlage darstellen kann. Prüfen
            Sie das Resultat auf Plausibilität und korrigieren Sie die Inhalte
            entsprechend.
          </p>
          <PressetexteRow isDraft={true} handleSave={handleSaveDialog} />
        </div>
      </section>

      <tp-dialog dialog-id="save-pressetext" id="save-pressetext-dialog">
        <div slot="title">Pressetext speichern</div>
        <p />

        <div class="pressetext-dialog-form">
          <tp-text-field
            id="name"
            appearance="dark"
            label="Name"
            error-message="Bitte ausfüllen"
            required
            onInput={(e) => setMetaDataStore("title", e.target.value)}
          />
          <tp-text-field
            id="autor"
            appearance="dark"
            label="Urheber"
            error-message="Bitte ausfüllen"
            required
            onInput={(e) => setMetaDataStore("urheber", e.target.value)}
          />
        </div>

        <div slot="action_buttons">
          <tp-button appearance="text-dark" onClick={closeSaveDialog}>
            ZURÜCK
          </tp-button>
          <tp-button id="saveButton" onClick={handleSave}>
            SPEICHERN
          </tp-button>
        </div>
      </tp-dialog>
    </>
  );
};

export default NewPressetext;
