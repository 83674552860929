import { Component, Show, createEffect, onMount, createSignal } from "solid-js";
import { createStore } from "solid-js/store";
import {
  applicationStore,
  pressetextCompletionStore,
  pressetextStore,
  PressetextType,
} from "../../../store";
import { formateDateString, getToday } from "../../../utils/date-utils";
import { openExternalLink } from "../../../utils/download";

import {
  draftPressetext,
  draftPressetextForSocialMedia,
} from "../../../services/api/search-api-handler";

import "./pressetexte-row.scss";

import deleteIconPath from "../../shared/icons/delete.svg";
import saveIconPath from "../../shared/icons/save.svg";
import openEyeconPath from "../../notes/note-row/icons/eye-open.svg";
import cancelIconPath from "../../shared/icons/cancel.svg";
import editIconPath from "../../shared/icons/edit.svg";
import closedEyeIconPath from "../../notes/note-row/icons/eye-closed.svg";

interface PressetexteRowProps {
  pressetext?: PressetextType;
  handleSave?: (pressetext) => void;
  handleDelete?: (event, id) => void;
  isDraft: boolean;
}

const createEmptyPressetext = () => {
  return {
    id: "",
    pressetextId: "",
    draft: true,
    themaId: "",
    thema: "",
    medientitel: "",
    gattung: "",
    erscheinungstermin: "",
    ansprechpartner: {},
    companyName: "",
    companyProfile: "",
    companyContact: "",
    productName: "",
    productDetails: "",
    characterCount: 1000,
    subject: "",
    body: "",
    social: "",
    title: "",
    urheber: "",
    updateTime: getToday(),
  } as PressetextType;
};

const PressetexteRow: Component<PressetexteRowProps> = (props) => {
  const { setApplicationState } = applicationStore;

  const [isDetailBlockOpen, setIsDetailBlockOpen] =
    createSignal<boolean>(false);
  const [isEditMode, setIsEditMode] = createSignal<boolean>(false);
  const [isDraftMode, setIsDraftMode] = createSignal<boolean>(false);

  const { pressetextState, setPressetextState } = pressetextStore;
  const { pressetextCompletionState, setPressetextCompletionState } =
    pressetextCompletionStore;

  setPressetextCompletionState("showContent", false);
  setPressetextCompletionState("showSocialContent", false);

  const [rowStore, setRowStore] = createStore<PressetextType>({
    ...createEmptyPressetext(),
  });

  onMount(() => {
    if (props?.opened === "true") {
      setIsDetailBlockOpen(true);
    }
  });

  createEffect(() => {
    if (props.isDraft) {
      setIsDraftMode(true);
      setIsEditMode(true);
    }
    // const isEditing = props["isEditMode"] === "true";
    // if (props.isDraft || isEditing) {
    //   setIsEditMode(true);
    // }

    if (props?.pressetext) {
      const pressetextProp = props.pressetext;
      setRowStore({ ...props.pressetext });
      setRowStore("pressetextId", pressetextProp?.id);
      setRowStore("characterCount", 1000);
    }
    if (pressetextState.selectedTopic?.thema) {
      setRowStore({
        themaId: pressetextState.selectedTopic?.id,
        thema: pressetextState.selectedTopic?.thema,
        medientitel: pressetextState.selectedTopic?.medientitel,
        gattung: pressetextState.selectedTopic?.gattung,
        erscheinungstermin: pressetextState.selectedTopic?.erscheinungstermin,
        ansprechpartner: pressetextState.selectedTopic?.ansprechpartner,
      });
    }
  });

  const showErrorOnEmptyFields = (event) => {
    let validFields = true;

    const parentContainer = event.srcElement.closest(".detail-area");

    const companyNameField = parentContainer.querySelector("#company-name");
    const companyProfileField =
      parentContainer.querySelector("#company-profile");
    const companyContactField =
      parentContainer.querySelector("#company-contact");
    const productNameField = parentContainer.querySelector("#product-name");
    const productDetailsField =
      parentContainer.querySelector("#product-details");
    const characterCountField =
      parentContainer.querySelector("#character-count");

    companyNameField.removeAttribute("error");
    companyProfileField.removeAttribute("error");
    companyContactField.removeAttribute("error");
    productNameField.removeAttribute("error");
    productDetailsField.removeAttribute("error");
    characterCountField.removeAttribute("error");

    if (rowStore.companyName === "") {
      companyNameField.setAttribute("error", "");
      validFields = false;
    }

    if (rowStore.companyProfile === "") {
      companyProfileField.setAttribute("error", "");
      validFields = false;
    }

    if (rowStore.companyContact === "") {
      companyContactField.setAttribute("error", "");
      validFields = false;
    }

    if (rowStore.productName === "") {
      productNameField.setAttribute("error", "");
      validFields = false;
    }

    if (rowStore.productDetails === "") {
      productDetailsField.setAttribute("error", "");
      validFields = false;
    }

    if (!rowStore.characterCount) {
      characterCountField.setAttribute("error", "");
      validFields = false;
    }

    return validFields;
  };

  const onCreateDraft = async (event) => {
    if (showErrorOnEmptyFields(event) === false) return;

    setPressetextCompletionState("isLoading", true);
    const response = await draftPressetext(rowStore);
    setPressetextCompletionState("isLoading", false);
    if (response.ok) {
      const data = await response.json();
      // setRowStore("id", data.pressetextId);
      setRowStore("pressetextId", data.pressetextId);
      setRowStore("draft", data.draft);
      setRowStore("subject", data.subject);
      setRowStore("body", data.body);
      setPressetextCompletionState("showContent", true);
    }
  };

  const onCreateDraftForSocialMedia = async (event) => {
    if (showErrorOnEmptyFields(event) === false) return;

    // TODO clipboard, dann location.href='https://twitter.com'?

    const data = {
      pressetextId: rowStore.pressetextId,
      pressetext: rowStore.body,
      characterCount: rowStore.characterCount,
    };
    setPressetextCompletionState("isLoadingSocial", true);
    const response = await draftPressetextForSocialMedia(
      rowStore.pressetextId,
      data,
    );
    setPressetextCompletionState("isLoadingSocial", false);
    if (response.ok) {
      const data = await response.json();
      setRowStore("social", data.body);
      setPressetextCompletionState("showSocialContent", true);
    }
  };

  const resetPressetextRow = () => {
    if (isDraftMode()) {
      setApplicationState("showCollapsibleContent", false);
    } else {
      editDetail();
    }
    setPressetextState("selectedTopic", undefined);
    setRowStore(
      props?.pressetext
        ? { ...props.pressetext }
        : { ...createEmptyPressetext() },
    );
  };

  const closeEmailDialog = async () => {
    document
      .getElementById("copy-pressetext-to-clipboard-dialog")
      .dispatchEvent(new Event("copy-pressetext-to-clipboard:dialog:close"));
  };

  const handleConfirmSendPressetextViaEmail = async () => {
    await closeEmailDialog();
    const mailto =
      "mailto:" + `?subject=${encodeURIComponent(`${rowStore.subject}`)}`;
    openExternalLink(mailto, "Pressetext per E-Mail");
  };

  const onCopyAndSendPressetext = async () => {
    await navigator.clipboard.writeText(rowStore.body);
    document
      .getElementById("copy-pressetext-to-clipboard-dialog")
      .dispatchEvent(new Event("copy-pressetext-to-clipboard:dialog:open"));
  };

  const toggleDetailBlock = (e) => {
    let src = e.target.getAttribute("src");

    if (src === openEyeconPath) {
      src = closedEyeIconPath;
    } else {
      src = openEyeconPath;
    }

    e.target.setAttribute("src", src);

    setIsDetailBlockOpen((isDetailBlockOpen) => {
      return !isDetailBlockOpen;
    });
  };

  const editDetail = () => {
    setIsEditMode((isEditMode) => !isEditMode);
  };

  const setReadonly = () => ({
    ...(!isEditMode() ? { "attr:readonly": true } : {}),
  });

  return (
    <>
      <div class="pressetexte-row">
        <Show when={!isDraftMode()}>
          <div class="pressetexte-row-overview">
            <tp-text-field
              id="name"
              appearance="light"
              label="Name"
              required
              attr:value={rowStore.title}
              onInput={(e) => setRowStore("title", e.target.value)}
              {...setReadonly()}
            />
            <tp-text-field
              id="autor"
              appearance="light"
              label="Urheber"
              required
              attr:value={rowStore.urheber}
              onInput={(e) => setRowStore("urheber", e.target.value)}
              {...setReadonly()}
            />
            <tp-text-field
              id="autor"
              appearance="light"
              label="Änderungsdatum"
              required
              attr:value={formateDateString(rowStore.updateTime)}
              disabled="true"
            />

            <div class="icons">
              {isEditMode() ? (
                <img
                  src={saveIconPath}
                  height="28"
                  alt="Speichern"
                  onClick={() => props.handleSave(rowStore)}
                />
              ) : (
                <img
                  src={openEyeconPath}
                  height="28"
                  alt="Öffnen"
                  onClick={toggleDetailBlock}
                />
              )}

              {isEditMode() ? (
                <img
                  src={cancelIconPath}
                  height="28"
                  alt="Schließen"
                  onClick={editDetail}
                />
              ) : (
                <img
                  src={editIconPath}
                  height="28"
                  alt="Editieren"
                  onClick={editDetail}
                />
              )}

              <img
                src={deleteIconPath}
                height="28"
                alt="Löschen"
                onClick={(e) => props.handleDelete(e, rowStore.pressetextId)}
              />
            </div>
          </div>
        </Show>
        <div
          class={`detail-area ${isDetailBlockOpen() || isEditMode() ? "" : "hidden"}`}
        >
          <Show when={isEditMode() && rowStore.thema}>
            <div class="topic-detail">
              <span class="left">
                <span class="overflow-ellipsis">"{rowStore.thema}"</span>
                <br />
                <span class="overflow-ellipsis">
                  Medientitel: {rowStore.medientitel}
                  {rowStore.gattung ? " (" + rowStore.gattung + ")" : ""}
                </span>
                <br />
                Erscheinungstermin:{" "}
                {`${formateDateString(rowStore.erscheinungstermin)}`}
              </span>
              <Show when={rowStore.ansprechpartner}>
                <span class="right">
                  <span class="overflow-ellipsis">
                    {`${rowStore.ansprechpartner.vorname} ${rowStore.ansprechpartner.nachname}${rowStore.ansprechpartner.funktion ? " (" + rowStore.ansprechpartner.funktion + ")" : ""}`}
                  </span>
                  <br />
                  <span class="overflow-ellipsis">
                    Telefon: {rowStore.ansprechpartner.telefon}
                  </span>
                  <br />
                  E-Mail:{" "}
                  <span>
                    {rowStore.ansprechpartner.mail
                      ? rowStore.ansprechpartner.mail
                      : rowStore.ansprechpartner.email}
                  </span>
                  <br />
                </span>
              </Show>
            </div>
          </Show>

          <Show when={isEditMode()}>
            <div class="pressetexte-form-column-wrapper">
              <div class="pressetexte-form-column">
                <tp-text-field
                  id="company-name"
                  appearance="light"
                  label="Firmenname"
                  required
                  attr:value={rowStore.companyName}
                  {...setReadonly()}
                  onInput={(e) => setRowStore("companyName", e.target.value)}
                />
                <tp-text-field
                  id="company-profile"
                  appearance="light"
                  label="Firmenprofil"
                  required
                  attr:value={rowStore.companyProfile}
                  {...setReadonly()}
                  onInput={(e) => setRowStore("companyProfile", e.target.value)}
                />
                <tp-text-field
                  id="company-contact"
                  appearance="light"
                  label="Ansprechpartner"
                  required
                  attr:value={rowStore.companyContact}
                  {...setReadonly()}
                  onInput={(e) => setRowStore("companyContact", e.target.value)}
                />
              </div>

              <div class="pressetexte-form-column">
                <tp-text-field
                  id="product-name"
                  appearance="light"
                  label="Produktname"
                  required
                  attr:value={rowStore.productName}
                  {...setReadonly()}
                  onInput={(e) => setRowStore("productName", e.target.value)}
                />
                <tp-text-field
                  id="product-details"
                  appearance="light"
                  label="Produktbeschreibung"
                  required
                  attr:value={rowStore.productDetails}
                  {...setReadonly()}
                  onInput={(e) => setRowStore("productDetails", e.target.value)}
                />
                <tp-text-field
                  id="character-count"
                  appearance="light"
                  label="Ungefähre Zeichenanzahl (max. 3000)"
                  required
                  attr:value={rowStore.characterCount}
                  {...setReadonly()}
                  onInput={(e) => setRowStore("characterCount", e.target.value)}
                />
              </div>
            </div>
          </Show>

          <div class="pressetexte-form-generator-wrapper">
            <Show when={isEditMode()}>
              <div class="button-row">
                <tp-button
                  onClick={onCreateDraft}
                  appearance="secondary"
                  {...(pressetextCompletionState.isLoading && {
                    disabled: true,
                  })}
                >
                  PRESSETEXT ERSTELLEN
                </tp-button>
                <tp-text-button
                  onClick={resetPressetextRow}
                  style={{ "margin-left": "25px", "margin-top": "25px" }}
                >
                  VERWERFEN
                </tp-text-button>
              </div>
            </Show>

            <Show when={pressetextCompletionState.isLoading}>
              <tp-circular-progress />
            </Show>

            <Show
              when={
                pressetextCompletionState.showContent ||
                rowStore.subject ||
                rowStore.body ||
                rowStore.social
              }
            >
              <tp-text-field
                id="pressetext-subject"
                appearance="light"
                label="Überschrift"
                error-text="Bitte ausfüllen"
                attr:value={rowStore.subject}
                {...setReadonly()}
                onInput={(e) => setRowStore("subject", e.target.value)}
              />
              <tp-text-field
                id="pressetext-result"
                type="textarea"
                rows="10"
                appearance="light"
                label="Pressetext"
                error-text="Bitte ausfüllen"
                attr:value={rowStore.body}
                {...setReadonly()}
                onInput={(e) => setRowStore("body", e.target.value)}
              />

              <Show when={isEditMode()}>
                <div class="button-row">
                  <tp-button
                    appearance="secondary"
                    onClick={() => props.handleSave(rowStore)}
                  >
                    SPEICHERN
                  </tp-button>
                  <tp-button
                    onClick={onCopyAndSendPressetext}
                    style={{ "margin-left": "25px" }}
                  >
                    PER E-MAIL SENDEN
                  </tp-button>
                  <tp-button
                    onClick={onCreateDraftForSocialMedia}
                    style={{ "margin-left": "25px" }}
                  >
                    SOCIAL-MEDIA-POST GENERIEREN
                  </tp-button>
                </div>
              </Show>

              <Show when={pressetextCompletionState.isLoadingSocial}>
                <tp-circular-progress />
              </Show>

              <Show
                when={
                  pressetextCompletionState.showSocialContent || rowStore.social
                }
              >
                <tp-text-field
                  id="pressetext-short"
                  type="textarea"
                  rows="3"
                  appearance="light"
                  label="Tweet"
                  error-text="Bitte ausfüllen"
                  attr:value={rowStore.social}
                  {...setReadonly()}
                  onInput={(e) => setRowStore("social", e.target.value)}
                />
              </Show>
            </Show>
          </div>
        </div>
      </div>

      <tp-dialog
        dialog-id="copy-pressetext-to-clipboard"
        id="copy-pressetext-to-clipboard-dialog"
      >
        <div slot="title">Pressetext per E-Mail versenden</div>

        <p>
          Der Pressetext wurde in die Zwischenablage kopiert und Ihr E-Mail
          Programm wird gleich geöffnet.
        </p>
        <p>
          Sollte sich das E-Mail Programm nicht öffnen, dann starten Sie es
          bitte selbst und fügen den Pressetext aus der Zwischenablage ein.
        </p>

        <div slot="action_buttons">
          <tp-button appearance="text-dark" onClick={closeEmailDialog}>
            ZURÜCK
          </tp-button>
          <tp-button onClick={handleConfirmSendPressetextViaEmail}>
            WEITER
          </tp-button>
        </div>
      </tp-dialog>
    </>
  );
};

export default PressetexteRow;
