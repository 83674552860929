import { A, useNavigate } from "@solidjs/router";
import { Component, Show } from "solid-js";
import { createStore } from "solid-js/store";
import { fetchLogin } from "../../../services/api/session-api-handler";
import { loggedIn, logoutSession } from "./session";

import { ROUTES } from "../../../constants/routes";

import { applicationStore } from "../../../store";

import "./login.scss";
import AdminNavigation from "../navigation/admin-navigation/admin-navigation";

const Login: Component = () => {
  const navigate = useNavigate();

  const [loginStore, setLoginStore] = createStore({
    username: "",
    password: "",
  });

  const { applicationState } = applicationStore;

  const login = async () => {
    if (loginStore.username.length === 0 || loginStore.password.length === 0) {
      // das funktioniert so nicht mehr
      if (loginStore.username.length === 0) {
        // TODO get rid of that hack
        setLoginStore(
          "username",
          document
            .getElementById("username")
            .shadowRoot.getElementById("username")
            .shadowRoot.querySelector("input").value,
        );
      }

      if (loginStore.password.length === 0) {
        // TODO get rid of that hack
        setLoginStore(
          "password",
          document
            .getElementById("password")
            .shadowRoot.getElementById("password")
            .shadowRoot.querySelector("input").value,
        );
      }
    }

    const loginResponse = await fetchLogin({
      username: loginStore.username,
      password: loginStore.password,
    });

    const token = loginResponse.headers.get("x-auth-token");
    if (loginResponse.ok && token) {
      await loggedIn(loginStore.username, token);
    } else {
      const dialog = document.getElementById("password-forgot-dialog");
      dialog.dispatchEvent(new Event("password-forgot:dialog:open"));
    }
  };

  const logout = async () => {
    await logoutSession();
    setLoginStore({ username: "", password: "" });
  };

  const onEnter = async (event) => {
    if (event.key === "Enter") {
      setLoginStore("password", event.target.value);
      await login();
    }
  };
  const closeDialog = () => {
    const dialog = document.getElementById("password-forgot-dialog");
    dialog.dispatchEvent(new Event("password-forgot:dialog:close"));
  };

  const handlePasswortForgetButton = () => {
    closeDialog();
    navigate(ROUTES.passwordForget, { replace: true });
  };

  return (
    <>
      <div>
        <Show
          when={applicationState.isLoggedIn === false}
          fallback={
            <div class="loggedin">
              Angemeldet als {sessionStorage.getItem("ls.auth_username")}{" "}
              <tp-text-button appearance="dark" onClick={logout}>
                <b>Ausloggen</b>
              </tp-text-button>
              <AdminNavigation />
            </div>
          }
        >
          <div class="login-field">
            <tp-text-field
              id="username"
              label="Benutzername"
              required
              autocomplete="username"
              onFocusOut={(e) => setLoginStore("username", e.target.value)}
            />

            <tp-text-field
              id="password"
              label="Passwort"
              type="password"
              required
              autocomplete="password"
              onInput={(e) => setLoginStore("password", e.target.value)}
              onKeyDown={onEnter}
            />

            <tp-text-button appearance="dark" onClick={login}>
              Login
            </tp-text-button>
          </div>
          <div class="subline">
            <A href={ROUTES.passwordForget}>Passwort vergessen</A>
          </div>
        </Show>
      </div>
      <tp-dialog dialog-id="password-forgot" id="password-forgot-dialog">
        <div slot="title">Login fehlgeschlagen</div>

        <p>Bitte überprüfen Sie Ihre Eingaben auf Richtigkeit.</p>
        <p>
          Sollten Sie ihr Passwort vergessen haben, können Sie jederzeit ein
          neues anfordern.
        </p>

        <div slot="action_buttons">
          <tp-button appearance="text-dark" onClick={() => closeDialog()}>
            ZURÜCK
          </tp-button>

          <tp-button onClick={handlePasswortForgetButton}>
            ICH HABE MEIN PASSWORT VERGESSEN
          </tp-button>
        </div>
      </tp-dialog>
    </>
  );
};

export default Login;
